import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller<HTMLFormElement> {
  declare debouncedSubmit: (params: never) => void;

  initialize() {
    this.debouncedSubmit = debounce(this.submit, 400);
  }

  submit({ params }) {
    if (params.submitter) {
      this.element.requestSubmit(this.find(params.submitter));
    } else {
      this.element.requestSubmit();
    }
  }

  private find(id: string) {
    return <HTMLElement>document.getElementById(id) || this.notFound(id);
  }

  private notFound(id: string) {
    throw new Error(`Element with ID "${id}" not found`);
  }
}
