import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="error"
export default class extends Controller<HTMLElement> {
  connect() {
    document.addEventListener("turbo:before-fetch-response", this.handleTurboFrameLoad.bind(this));
  }

  handleTurboFrameLoad(event) {
    const { statusCode } = event.detail.fetchResponse;
    if (statusCode === 404) {
      this.turboFrameError("The requested data could not be found.", event);
    } else if (statusCode === 500) {
      this.turboFrameError("Something went wrong!", event);
    }
  }

  handleTurboFrameMissing(event) {
    this.turboFrameError("There was an error loading your data.", event);
  }

  turboFrameError(message, event) {
    const { target } = event;
    event.preventDefault();
    target.innerHTML = `<div class="flex flex-col items-center justify-center gap-3 p-5 rounded min-h-[320px] bg-slate-100">
      <span class="text-3xl leading-none text-center emoji">🤖</span>
      <div class="flex flex-col items-center justify-center gap-2">
        <h3 class="text-xl m-0">${message}</h3>
      </div>
      <p class="text-center leading-normal m-0">
        Try refreshing the page; if the problem persists
        <a href="mailto:support@buildkite.com" class="text-green-600 hover:underline">let us know.</a>
      </p>
    </div>`;
  }
}
