import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="storages-hdd"
export default class extends Controller {
  static targets = ["inSound", "outSound", "innerCircle", "sticker"];
  declare inSoundTarget: HTMLAudioElement;
  declare outSoundTarget: HTMLAudioElement;
  declare innerCircleTarget: HTMLDivElement;
  declare stickerTargets: [HTMLImageElement];
  declare providerValue: string;

  static classes = ["innerCircleOn", "hidden"];
  declare innerCircleOnClasses: Array<string>;
  declare hiddenClass: string;
  static values = { provider: String };

  pressPowerButton() {
    this.playSound(this.inSoundTarget);
  }

  releasePowerButton() {
    this.playSound(this.outSoundTarget);
    this.toggleInnerCircleStyle();
  }

  setProvider(event) {
    this.providerValue = event.target.value;
  }

  providerValueChanged() {
    this.stickerTargets.forEach((sticker) => {
      if (sticker.dataset.provider === this.providerValue) {
        sticker.classList.remove(this.hiddenClass);
      } else {
        sticker.classList.add(this.hiddenClass);
      }
    });
  }

  private playSound(audioElement: HTMLAudioElement) {
    audioElement.currentTime = 0;
    audioElement.play();
  }

  private toggleInnerCircleStyle() {
    this.innerCircleOnClasses.forEach((className) => {
      this.innerCircleTarget.classList.toggle(className);
    });
  }
}
